import { Component, OnInit } from '@angular/core';
import { SeriesOptionsType } from 'highcharts';


@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit {

  constructor() { }
  public isChartVisible = true;
  public selectedChartEntries: SeriesOptionsType[];
  public tableChange = 1;
  public selectedCurrencySymbol: string;
  public selectedOpexCurrency: any[];
  public selectedCapexCurrency: any[];
  public selectedAssetCurrency: any[];
  public subheaderOpex: string;
  public subheaderCapex: string;
  public subheaderAssets: string;
  public selectedTotal2019;
  public selectedTotal2020;
  public selectedTotal2021;

  // Bar Chart Data
  public randChartEntries: SeriesOptionsType[] = [
    {
    type: 'column',
    name: 'South Africa',
    data: [['2 376 000', 2376000], ['2 257 000', 2257000], ['2 739 000', 2739000]],
    color: 'rgb(5 10 76)'
  }, {
    type: 'column',
    name: 'USA',
    data: [['6 495 000', 6495000], ['7 788 000', 7788000], ['5 078 000', 5078000]],
    color: 'rgb(195 97 50)'
  }, {
    type: 'column',
    name: 'Germany',
    data: [['1 290 000', 1290000], ['1 849 000', 1849000], ['1 541 000', 1541000]],
    color: '#0000007a'
  }, {
    type: 'column',
    name: 'Sweden',
    data: [['189 000', 189000], ['128 000', 128000], ['40 000', 40000]],
   color: 'rgb(217 187 9)'
  }, {
    type: 'column',
    name: 'Volunteers',
    data: [['733 000', 733000], ['165 000', 165000], ['21 000', 21000]],
    color: '#2d66a7'
  },
];

public dollarChartEntries: SeriesOptionsType[] = [
  {
    type: 'column',
    name: 'South Africa',
    data: [['161 000', 161000], ['153 000', 153000], ['185 000', 185000]],
    color: 'rgb(5 10 76)'
  }, {
    type: 'column',
    name: 'USA',
    data: [ ['439 000', 439000], ['526 000', 526000], ['343 000', 343000]],
    color: 'rgb(195 97 50)'
  }, {
    type: 'column',
    name: 'Germany',
    data: [['87 000', 87000], ['125 000', 125000], ['104 000', 104000]],
    color: '#0000007a'
  }, {
    type: 'column',
    name: 'Sweden',
    data: [['13 000', 13000], ['9000', 9000], ['3000', 3000]],
   color: 'rgb(217 187 9)'
  }, {
    type: 'column',
    name: 'Volunteers',
    data: [['50 000', 50000], ['11 000', 11000], ['1000', 1000]],
    color: '#2d66a7'
  },
];

public euroChartEntries: SeriesOptionsType[] = [
  {
    type: 'column',
    name: 'South Africa',
    data: [['136 000', 136000], ['129 000', 129000], ['157 000', 157000]],
    color: 'rgb(5 10 76)'
  }, {
    type: 'column',
    name: 'USA',
    data: [['371 000', 371000], ['445 000', 445000], ['290 000', 290000]],
    color: 'rgb(195 97 50)'
  }, {
    type: 'column',
    name: 'Germany',
    data: [['74 000', 74000], ['106 000', 106000], ['88 000', 88000]],
    color: '#0000007a'
  }, {
    type: 'column',
    name: 'Sweden',
    data: [['11 000', 11000], ['7000', 7000], ['2000', 2000]],
   color: 'rgb(217 187 9)'
  }, {
    type: 'column',
    name: 'Volunteers',
    data: [['42 000', 42000], ['9000', 9000], ['1000', 1000]],
    color: '#2d66a7'
  },
];


// Opex Donut Chart Data

public opexRandChartEntries: any[] = [
  { name: 'Support services & activities', y: 2738000, color: 'rgb(77, 75, 75)' },
  { name: 'Management expenses', y: 1125000, color: '#e3b014' },
  { name: 'Food and Nutrition', y: 763000, color: '#18189f' },
  { name: 'Shelter and Care', y: 606000, color: 'rgb(206, 111, 2)' },
  { name: 'Protection', y: 446000, color: 'rgb(177, 50, 0)' },
  { name: 'Health', y: 247000, color: 'rgb(248, 233, 97)' },
  { name: 'Psychosocial', y: 660000, color: 'rgb(86, 195, 223)' },
  { name: 'Education and Skills Training', y: 2549000, color: 'rgb(92, 202, 116)' },
  { name: 'Spirituality and Community', y: 301000, color: '#4d4df7' },
  { name: 'Economic', y: 548000, color: 'rgb(51, 27, 5)' },
];

public opexDollarChartEntries: any[] = [
  { name: 'Support services & activities', y: 185000, color: 'rgb(77, 75, 75)' },
  { name: 'Management expenses', y: 76000, color: '#e3b014' },
  { name: 'Food and Nutrition', y: 52000, color: '#18189f' },
  { name: 'Shelter and Care', y: 41000, color: 'rgb(206, 111, 2)' },
  { name: 'Protection', y: 30000, color: 'rgb(177, 50, 0)' },
  { name: 'Health', y: 17000, color: 'rgb(248, 233, 97)' },
  { name: 'Psychosocial', y: 45000, color: 'rgb(86, 195, 223)' },
  { name: 'Education and Skills Training', y: 172000, color: 'rgb(92, 202, 116)' },
  { name: 'Spirituality and Community', y: 20000, color: '#4d4df7' },
  { name: 'Economic', y: 37000, color: 'rgb(51, 27, 5)' },
];

public opexEuroChartEntries: any[] = [
  { name: 'Support services & activities', y: 156000, color: 'rgb(77, 75, 75)' },
  { name: 'Management expenses', y: 64000, color: '#e3b014' },
  { name: 'Food and Nutrition', y: 44000, color: '#18189f' },
  { name: 'Shelter and Care', y: 35000, color: 'rgb(206, 111, 2)' },
  { name: 'Protection', y: 26000, color: 'rgb(177, 50, 0)' },
  { name: 'Health', y: 14000, color: 'rgb(248, 233, 97)' },
  { name: 'Psychosocial', y: 38000, color: 'rgb(86, 195, 223)' },
  { name: 'Education and Skills Training', y: 146000, color: 'rgb(92, 202, 116)' },
  { name: 'Spirituality and Community', y: 17000, color: '#4d4df7' },
  { name: 'Economic', y: 31000, color: 'rgb(51, 27, 5)' },
];

// Capex Donut Chart Data

public capexRandChartEntries: any[] = [
  { name: 'Job Creation', y: 162000, color: 'rgb(255, 217, 0)' },
  { name: 'Program Equipment', y: 6000, color: 'rgb(100, 0, 146)' },
  { name: 'Volunteer house – PE', y: 428000, color: 'rgb(28, 83, 36)' },
  { name: 'Residential stands – PE', y: 47000, color: 'rgb(20, 18, 97)' },
  { name: 'Foster Homes – PV ', y: 255000, color: 'rgb(84, 209, 35)' },
  { name: 'Foster Homes – PT    ', y: 32000, color: 'rgb(86, 195, 223)' },
  { name: 'Community Centre – PE Ikhaya', y: 65000, color: 'rgb(135, 140, 153)' },
  { name: 'Community Centre – PV ', y: 341000, color: 'rgb(18, 18, 19)' },
  { name: 'Community Centre – PT   ', y: 67000, color: 'rgb(153, 43, 9)' },
  { name: 'Community Centre – PE ', y: 45000, color: 'rgb(22, 68, 197)' },
];

public capexDollarChartEntries: any[] = [
  { name: 'Job Creation', y: 11000, color: 'rgb(255, 217, 0)' },
  { name: 'Program Equipment', y: 400, color: 'rgb(100, 0, 146)' },
  { name: 'Volunteer house – PE', y: 29000, color: 'rgb(28, 83, 36)' },
  { name: 'Residential stands – PE', y: 3000, color: 'rgb(20, 18, 97)' },
  { name: 'Foster Homes – PV ', y: 17000, color: 'rgb(84, 209, 35)' },
  { name: 'Foster Homes – PT    ', y: 2000, color: 'rgb(86, 195, 223)' },
  { name: 'Community Centre – PE Ikhaya', y: 4000, color: 'rgb(135, 140, 153)' },
  { name: 'Community Centre – PV ', y: 23000, color: 'rgb(18, 18, 19)' },
  { name: 'Community Centre – PT   ', y: 5000, color: 'rgb(153, 43, 9)' },
  { name: 'Community Centre – PE ', y: 3000, color: 'rgb(22, 68, 197)' },
];

public capexEuroChartEntries: any[] = [
  { name: 'Job Creation', y: 9000, color: 'rgb(255, 217, 0)' },
  { name: 'Program Equipment', y: 400, color: 'rgb(100, 0, 146)' },
  { name: 'Volunteer house – PE', y: 24000, color: 'rgb(28, 83, 36)' },
  { name: 'Residential stands – PE', y: 3000, color: 'rgb(20, 18, 97)' },
  { name: 'Foster Homes – PV ', y: 15000, color: 'rgb(84, 209, 35)' },
  { name: 'Foster Homes – PT    ', y: 2000, color: 'rgb(86, 195, 223)' },
  { name: 'Community Centre – PE Ikhaya', y: 4000, color: 'rgb(135, 140, 153)' },
  { name: 'Community Centre – PV ', y: 19000, color: 'rgb(18, 18, 19)' },
  { name: 'Community Centre – PT   ', y: 4000, color: 'rgb(153, 43, 9)' },
  { name: 'Community Centre – PE ', y: 3000, color: 'rgb(22, 68, 197)' },
];

// Assets Donut Chart Data

public assetsRandChartEntries: any[] = [
  { name: 'Motor vehicles', y: 194000, color: 'rgb(51, 27, 5)' },
    { name: 'Cash (Designated & Surplus funds)', y: 3129000, color: 'rgb(135, 140, 153)' },
    { name: 'Receivables', y: 901000, color: 'rgb(92, 202, 116)' },
    { name: 'Land, buildings, equipment - PE', y: 3902000, color: 'rgb(177, 50, 0)' },
    { name: 'Land, buildings, equipment - PT', y: 6423000, color: 'rgb(86, 195, 223)' },
    { name: 'Land, buildings, equipment - PV', y: 6681000, color: 'rgb(255, 217, 0)' },
    { name: 'OVC software', y: 362000, color: 'rgb(28, 83, 36)' },
    { name: 'Job creation and Program Equipment', y: 562000, color: 'rgb(20, 18, 97)' },
];

public assetsDollarChartEntries: any[] = [
  { name: 'Motor vehicles', y: 13000, color: 'rgb(51, 27, 5)' },
    { name: 'Cash (Designated & Surplus funds)', y: 211000, color: 'rgb(135, 140, 153)' },
    { name: 'Receivables', y: 61000, color: 'rgb(92, 202, 116)' },
    { name: 'Land, buildings, equipment - PE', y: 263000, color: 'rgb(177, 50, 0)' },
    { name: 'Land, buildings, equipment - PT', y: 434000, color: 'rgb(86, 195, 223)' },
    { name: 'Land, buildings, equipment - PV', y: 451000, color: 'rgb(255, 217, 0)' },
    { name: 'OVC software', y: 24000, color: 'rgb(28, 83, 36)' },
    { name: 'Job creation and Program Equipment', y: 38000, color: 'rgb(20, 18, 97)' },
];

public assetsEuroChartEntries: any[] = [
  { name: 'Motor vehicles', y: 11000, color: 'rgb(51, 27, 5)' },
    { name: 'Cash (Designated & Surplus funds)', y: 179000, color: 'rgb(135, 140, 153)' },
    { name: 'Receivables', y: 51000, color: 'rgb(92, 202, 116)' },
    { name: 'Land, buildings, equipment - PE', y: 223000, color: 'rgb(177, 50, 0)' },
    { name: 'Land, buildings, equipment - PT', y: 367000, color: 'rgb(86, 195, 223)' },
    { name: 'Land, buildings, equipment - PV', y: 382000, color: 'rgb(255, 217, 0)' },
    { name: 'OVC software', y: 21000, color: 'rgb(28, 83, 36)' },
    { name: 'Job creation and Program Equipment', y: 32000, color: 'rgb(20, 18, 97)' },
];

  public loadSelectedChart (value: number) {
    this.isChartVisible = false;
    switch (value) {
      case 1:
        this.selectedCurrencySymbol = "R";
        this.subheaderOpex = "R9 983 000";
        this.subheaderCapex = "R1 448 000";
        this.subheaderAssets = "R22 154 000";
        this.selectedChartEntries = this.randChartEntries;
        this.selectedOpexCurrency = this.opexRandChartEntries;
        this.selectedCapexCurrency = this.capexRandChartEntries;
        this.selectedAssetCurrency = this.assetsRandChartEntries;
        this.selectedTotal2019 = "R 11 083 000";
        this.selectedTotal2020 = "R 12 187 000";
        this.selectedTotal2021 = "R 9 419 000";
        setTimeout(() => {
          this.isChartVisible = true;
        }, 100);
        break;
      case 2:
        this.selectedCurrencySymbol = "$";
        this.subheaderOpex = "$675 000";
        this.subheaderCapex = "$97 400";
        this.subheaderAssets = "$1 495 000";
        this.selectedChartEntries = this.dollarChartEntries;
        this.selectedOpexCurrency = this.opexDollarChartEntries;
        this.selectedCapexCurrency = this.capexDollarChartEntries;
        this.selectedAssetCurrency = this.assetsDollarChartEntries;
        this.selectedTotal2019 = "$ 750 000";
        this.selectedTotal2020 = "$ 824 000";
        this.selectedTotal2021 = "$ 636 000";
        setTimeout(() => {
          this.isChartVisible = true;
        }, 100);
        break;
      case 3:
        this.selectedCurrencySymbol = "€";
        this.subheaderOpex = "€571 000";
        this.subheaderCapex = "€83 400";
        this.subheaderAssets = "€1 266 000";
        this.selectedChartEntries = this.euroChartEntries;
        this.selectedOpexCurrency = this.opexEuroChartEntries;
        this.selectedCapexCurrency = this.capexEuroChartEntries;
        this.selectedAssetCurrency = this.assetsEuroChartEntries;
        this.selectedTotal2019 = "€ 634 000";
        this.selectedTotal2020 = "€ 696 000";
        this.selectedTotal2021 = "€ 538 000";
        setTimeout(() => {
          this.isChartVisible = true;
        }, 100);
        break;
    }
  }

  ngOnInit(): void {
    this.selectedCurrencySymbol = "R";
    this.subheaderOpex = "R9 983 000";
    this.subheaderCapex = "R1 448 000";
    this.subheaderAssets = "R22 154 000";
    this.selectedChartEntries = this.randChartEntries;
    this.selectedOpexCurrency = this.opexRandChartEntries;
    this.selectedCapexCurrency = this.capexRandChartEntries;
    this.selectedAssetCurrency = this.assetsRandChartEntries;
    this.selectedTotal2019 = "R 11 083 000";
    this.selectedTotal2020 = "R 12 187 000";
    this.selectedTotal2021 = "R 9 419 000";
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

}
