import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { preserveWhitespacesDefault } from '@angular/compiler';
import { color, SeriesOptionsType } from 'highcharts';

@Component({
  selector: 'app-bar-charts',
  templateUrl: './bar-charts.component.html',
  styleUrls: ['./bar-charts.component.css']
})
export class BarChartsComponent implements OnInit {
  @Input()
  public height: any;

  @Input()
  public width: any;

  @Input()
  public barChartEntries: SeriesOptionsType[];

  @Input()
  public currencySymbol: string;
  // public chartData = [8100000, 8945000, 11083000];
  public categories = ['2019', '2020', '2021'];

  public barchart: Chart;
  constructor() {}

  ngOnInit() {
    this.barchart = this.createBarChart();
  }

  public createBarChart(): Chart {
    const chartConfig = new Chart({
      chart: {
        type: 'column',
        inverted: false,
        height: this.height,
        width: this.width,
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Helvetica'
        }
      },
      title: {
        text: 'Total Income',
        verticalAlign: 'top',
        y: 25,
        style: {
          fontSize: '38px',
          color: '#d8d8db',
          textOutline: '2px 1px 0 #000',
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: '',
        style: {
          fontSize: '23px'
        },
        pointFormat: `${ this.currencySymbol } {point.y}`
      },
      legend: {
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        itemStyle: {
          fontSize: '22px',
          color: '#d8d8db',
          textOutline: '2px 1px 0 #000',
        },
        itemHoverStyle: {
          color: '#d8d8db',
          textOutline: '3px 2px 0 #000',
      },
        backgroundColor: 'transparent'
      },
      credits: {
        enabled: false
      },

      xAxis: {
        categories: this.categories,
        labels: {
          style: {
            fontSize: '22px',
            color: '#d8d8db',
            textOutline: '2px 1px 0 #000',
          }
        },
        title: {
          text: 'Year',
          style: {
            fontSize: '34px',
            color: '#d8d8db',
            textOutline: '2px 1px 0 #000',
          }
        }
      },
      yAxis: {
        labels: {
          style: {
            fontSize: '22px',
            color: '#d8d8db',
            textOutline: '2px 1px 0 #000',
          }
        },
        title: {
          text: 'Income',
          style: {
            fontSize: '34px',
            color: '#d8d8db',
            textOutline: '2px 1px 0 #000',
          }
        },
        stackLabels: {
          enabled: true,
          style: {
            color: '#d8d8db',
            textOutline: '2px 1px 0 #000',
          }
        }
      },
      plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true
            }
        }
    },
      exporting: {
        enabled: false
      },
      series: this.barChartEntries,
    });

    return chartConfig;
  }
}
